/* tslint:disable */
/* eslint-disable */
/**
 * AutoMl Application API
 * Documentation of API endpoints of AutoMl Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileDirectUploadFinishInput,
  FileDirectUploadLocalOutput,
  FileDirectUploadStartOutput,
} from '../models/index';
import {
    FileDirectUploadFinishInputFromJSON,
    FileDirectUploadFinishInputToJSON,
    FileDirectUploadLocalOutputFromJSON,
    FileDirectUploadLocalOutputToJSON,
    FileDirectUploadStartOutputFromJSON,
    FileDirectUploadStartOutputToJSON,
} from '../models/index';

export interface ApiUploadFinishCreateRequest {
    id: string;
    labelId?: string;
}

export interface ApiUploadLocalCreateRequest {
    fileId: string;
    file?: Blob | null;
}

export interface ApiUploadStartCreateRequest {
    origFilename: string;
    dataset: number;
}

/**
 *
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * Finish endpoint of the FIle upload logic. Sets upload_finished_at value to ImageFile model  Args:     request:  Returns:     dict: containing id of image_file
     */
    async apiUploadFinishCreateRaw(requestParameters: ApiUploadFinishCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDirectUploadFinishInput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUploadFinishCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.labelId !== undefined) {
            formParams.append('label_id', requestParameters.labelId as any);
        }

        const response = await this.request({
            path: `/api/upload/finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDirectUploadFinishInputFromJSON(jsonValue));
    }

    /**
     * Finish endpoint of the FIle upload logic. Sets upload_finished_at value to ImageFile model  Args:     request:  Returns:     dict: containing id of image_file
     */
    async apiUploadFinishCreate(requestParameters: ApiUploadFinishCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDirectUploadFinishInput> {
        const response = await this.apiUploadFinishCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Development endpoint for file upload which takes files when s3 bucket is not used.  Args:     request:     file_id:     *args:     **kwargs:  Returns:     dict: response containing \"ok\" key which is true on successfully upload
     */
    async apiUploadLocalCreateRaw(requestParameters: ApiUploadLocalCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDirectUploadLocalOutput>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiUploadLocalCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/upload/local/{file_id}/`.replace(`{${"file_id"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDirectUploadLocalOutputFromJSON(jsonValue));
    }

    /**
     * Development endpoint for file upload which takes files when s3 bucket is not used.  Args:     request:     file_id:     *args:     **kwargs:  Returns:     dict: response containing \"ok\" key which is true on successfully upload
     */
    async apiUploadLocalCreate(requestParameters: ApiUploadLocalCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDirectUploadLocalOutput> {
        const response = await this.apiUploadLocalCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start endpoint of the FIle upload logic.  Args:     request:     *args:     **kwargs:  Returns:     Presigned data for handling client side file upload directly to s3 bucket.
     */
    async apiUploadStartCreateRaw(requestParameters: ApiUploadStartCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDirectUploadStartOutput>> {
        if (requestParameters.origFilename === null || requestParameters.origFilename === undefined) {
            throw new runtime.RequiredError('origFilename','Required parameter requestParameters.origFilename was null or undefined when calling apiUploadStartCreate.');
        }

        if (requestParameters.dataset === null || requestParameters.dataset === undefined) {
            throw new runtime.RequiredError('dataset','Required parameter requestParameters.dataset was null or undefined when calling apiUploadStartCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.origFilename !== undefined) {
            formParams.append('orig_filename', requestParameters.origFilename as any);
        }

        if (requestParameters.dataset !== undefined) {
            formParams.append('dataset', requestParameters.dataset as any);
        }

        const response = await this.request({
            path: `/api/upload/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDirectUploadStartOutputFromJSON(jsonValue));
    }

    /**
     * Start endpoint of the FIle upload logic.  Args:     request:     *args:     **kwargs:  Returns:     Presigned data for handling client side file upload directly to s3 bucket.
     */
    async apiUploadStartCreate(requestParameters: ApiUploadStartCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDirectUploadStartOutput> {
        const response = await this.apiUploadStartCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
